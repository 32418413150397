export const environment = {
  useMocks: false,
  useProdMode: true,
  enableConsoleLog: true,
  enableComponentGallery: false,
  apollo: {
    apiUrl: 'https://staging-front.klobal.cz/graphql',
    subscriptionsUrl: 'wss://staging-front.klobal.cz/subscriptions',
  },
  rest: {
    apiV2Url: "https://staging-front.klobal.cz/api"
  },
  sentry: {
    allow: true,
    dsn: 'https://4051dda49beee1d8078539cb96febd35@o4506434096201728.ingest.us.sentry.io/4506828862914560',
    allowReplay: false
  },
  routerTracing: false,
  environment: 'staging',
  appVersion: 'stg-v2024.48.0',
  onlineInspectionUrl: 'https://staging-portal.klobal.cz/online-task-inspection'
};
